$(document).ready(function () {
    initMenu();
    initPartners();
    initMap();
    initLogin();
    initTables();
});

function resize() {

}

var timo;
window.onresize = function () {
    clearTimeout(timo);
    timo = setTimeout(resize, 100);
};

function initMenu() {
    $('.navbar-toggler').click(function(){
        $(this).toggleClass('active');
        $(this).parent().toggleClass('clicked');
    });

    $('.overlay').click(function(){
        $('.navbar-toggler').click();
    });

    $('.dropdown-toggle').click(function(){
        $(this).siblings('.dropdown').slideToggle();

    });
}

function initPartners() {
    var wrap = $('.partner-wrap');

    if(wrap.length <1){
        return;
    }

    var owl = $('.partner-wrap').owlCarousel({
        loop: false,
        items: 8,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        mouseDrag: false,
        responsive: {
            0:{
                items: 1,
                loop:true
            },
            280: {
                items:2,
                loop:true
            },
            400:{
                items:3,
                loop:true
            },
            680:{
                items:5,
                loop:true
            },
            820:{
                items:6,
                loop:true
            },
            1100:{
                items:8,
                loop:false
            }
        },
        onInitialized: function(){
            $('.partner-item', owl).css('display', 'flex');
        }
    });
}

function initMap(){
    var loc = {lat: 57.092968, lng: 24.545370};
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 15,
        center: loc,
        disableDefaultUI: true
    });

    var marker = new google.maps.Marker({
        position:loc,
        map: map,
        icon: '../images/html/pin.svg'
    });
}

function initLogin() {
    $('.login-link').click(function(){
        $(this).parent().toggleClass('open-form');
    });

    $('.overlay').click(function(){
        $(this).parent().removeClass('open-form');
    });
}

function initTables() {
    $('.text table').wrap('<div class="table-wrap"></div>');
}

$('p').selectionSharer();